
export default {
  props: {
    images: {
      type: Array,
      required: true,
    },
    gallerySettings: {
      type: Object,
      default() {
        return {
          speed: 100,
          slidesToShow: 1,
          centerMode: true,
          slidesToScroll: 1,
          variableWidth: true,
          focusOnSelect: true,
          lazyLoad: 'ondemand',
          asNavFor: '.slider-nav',
          responsive: [
            {
              breakpoint: 768,
              settings: {
                // dots: true,
              },
            },
          ],
        }
      },
    },
    navSettings: {
      type: Object,
      default() {
        return {
          slidesToShow: 5,
          centerMode: true,
          slidesToScroll: 1,
          variableWidth: true,
          focusOnSelect: true,
          lazyLoad: 'ondemand',
          asNavFor: '.slider-gallery',
          swipeToSlide: true,
          arrows: false,
        }
      },
    },
    initialSlide: {
      type: Number,
      default: 4,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    showNav: {
      type: Boolean,
      default: true,
    },
    galleryClass: {
      type: String,
      default: 'cursor-zoom-in',
    },
    navClass: {
      type: String,
      default: 'cursor-pointer',
    },
  },

  computed: {
    rtl() {
      return this.dir === 'rtl'
    },
  },

  mounted() {
    this.$refs.gallery?.play()

    setTimeout(() => {
      this.$refs.gallery?.pause()
    }, 3000)
  },

  methods: {
    clickSlide(index) {
      this.$emit('clickSlide', index)
    },
  },
}
