
import mapboxgl from 'mapbox-gl'
import MapboxLanguage from '@mapbox/mapbox-gl-language'

export default {
  props: {
    place: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      map: {},
    }
  },
  head() {
    return {
      link: [
        {
          rel: 'stylesheet',
          href: 'https://api.mapbox.com/mapbox-gl-js/v2.4.1/mapbox-gl.css',
        },
      ],
    }
  },
  watch: {
    colorMode(value) {
      this.map.setStyle(
        value === 'dark'
          ? 'mapbox://styles/mapbox/dark-v10'
          : 'mapbox://styles/mapbox/streets-v11',
      )
    },
  },
  mounted() {
    mapboxgl.accessToken = this.$config.mapboxglAccessToken

    if (!['loaded', 'deferred'].includes(mapboxgl.getRTLTextPluginStatus())) {
      mapboxgl.setRTLTextPlugin(
        'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
        null,
        true,
      )
    }

    this.mapInit()
  },
  methods: {
    mapInit() {
      this.map = new mapboxgl.Map({
        container: 'map',
        style:
          this.colorMode === 'dark'
            ? 'mapbox://styles/mapbox/dark-v10'
            : 'mapbox://styles/mapbox/streets-v11',
        center: [this.place.lng, this.place.lat],
        zoom: 15,
        maxPitch: 0,
        attributionControl: false,
      })

      this.map.addControl(new mapboxgl.NavigationControl())

      this.map.addControl(
        new mapboxgl.FullscreenControl({
          container: document.querySelector('#map'),
        }),
      )

      this.map.addControl(
        new MapboxLanguage({
          defaultLanguage: 'mul',
        }),
      )

      new mapboxgl.Marker()
        .setLngLat([this.place.lng, this.place.lat])
        .addTo(this.map)
    },
  },
}
