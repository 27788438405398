
export default {
  props: {
    type: {
      type: String,
      default: 'sidebar',
    },
    title: {
      type: [String, Number],
      default: null,
    },
  },
}
